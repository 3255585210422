/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { JsonLdSiteNavigation } from 'blog-components'

import startCase from 'lodash/startCase'

import './style.scss'

class SiteNavi extends React.Component {
  collectCategories(posts) {
    let categories = new Set()
    for (let i = 0; i < posts.length; i++) {
      let item = posts[i]

      item.post.categories.forEach(category => {
        categories.add(category)
      })
    }

    return categories
  }

  buildCategoryNavItems(categories) {
    let items = []

    categories.forEach(category => {
      let url = '/category/' + category + '/'
      items.push({
        url: url,
        name: startCase(category),
      })
    })

    return items
  }

  getCategoryDropdownItems(categoryNavItems) {
    let items = []

    categoryNavItems.forEach((categoryNavItem, index) => {
      items.push(
        <a
          key={index}
          className="dropdown-item"
          href={categoryNavItems[index].url}
        >
          {categoryNavItems[index].name}
        </a>
      )
    })

    return items
  }

  buildSiteNameItems(site, categoryNavItems) {
    let items = []

    categoryNavItems.forEach((categoryNavItem, index) => {
      items.push({
        url: site.siteMetadata.siteUrl + categoryNavItem.url,
        name: categoryNavItem.name,
      })
    })

    return items
  }

  render() {
    const { logo, posts, site } = this.props

    let categories = this.collectCategories(posts.posts)
    let categoryNavItems = this.buildCategoryNavItems(categories)
    let dropdownCategories = this.getCategoryDropdownItems(categoryNavItems)

    let siteNavItems = [
      { name: 'Home', url: site.siteMetadata.siteUrl },
      { name: 'Shop', url: 'https://www.perfectly-penned.com/collections/all' },
    ]

    siteNavItems = siteNavItems.concat(
      this.buildSiteNameItems(site, categoryNavItems)
    )

    return (
      <div>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(
              JsonLdSiteNavigation.generate(
                site.siteMetadata.siteUrl,
                siteNavItems
              )
            )}
          </script>
        </Helmet>
        {/* <ul className="nav navbar-dark nav-fill bg-primary justify-content-center text-center">
          <a className="nav-link no-gutters" href="/giveaway/">
            <li className="nav-item">
              <span className="navbar-text text-white display-6">
                TOp banner
              </span>
            </li>
          </a>
        </ul> */}

        <nav className="navbar pt-3">
          <div
            className="container d-flex flex-column flex-md-row justify-content-center fadein-3"
            data-emergence="visible"
          >
            <Link to="/">
              <img id="main-logo" src={logo} alt="Perfectly Penned Logo" />
            </Link>
          </div>
        </nav>
        <div className="row justify-content-center">
          <div className="col col-auto">
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.perfectly-penned.com/collections/all"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Shop
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Categories
                </a>
                <div className="dropdown-menu">{dropdownCategories}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default SiteNavi
