import React from 'react'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
import './style.scss'

import * as messages from '../../messages.json'

class Footer extends React.Component {
  buildFooterColumn(items) {
    let components = []

    items.forEach((item, index) => {
      components.push(
        <li key={index} className="py-1">
          <a
            className="text-muted"
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.text}
          </a>
        </li>
      )
    })

    return components
  }

  render() {
    const { logo } = this.props
    return (
      <footer className="mt-3 py-5 text-center text-white">
        <div className="row no-gutters">
          <div className="col-md-6 py-2">
            <h5>{messages.layouts.index.components.footer.col1.title}</h5>
            <ul className="list-unstyled text-small">
              {this.buildFooterColumn(
                messages.layouts.index.components.footer.col1.items
              )}
            </ul>
          </div>

          <div className="col-md-6 py-2">
            <h5>{messages.layouts.index.components.footer.col2.title}</h5>
            <ul className="list-unstyled text-small">
              {this.buildFooterColumn(
                messages.layouts.index.components.footer.col2.items
              )}
            </ul>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-4">
            <Link to="/">
              <img
                className="img-fluid bottom-logo"
                src={logo}
                alt="Perfectly Penned Logo"
              />
            </Link>
            <small className="d-block mb-3 text-muted text-center">
              {messages.layouts.index.components.footer.ownership.date}
            </small>
            <small className="d-block mb-3 text-muted text-center">
              {messages.layouts.index.components.footer.ownership.disclaimer}
            </small>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <small className="d-block mb-3 text-muted text-center">
            <Link className="text-muted" to="/terms-and-conditions/">
              {messages.layouts.index.components.footer.col2.items[1]}
            </Link>
          </small>
        </div>
      </footer>
    )
  }
}

export default Footer

Footer.propTypes = {
  logo: PropTypes.object.isRequired,
}
