import React from 'react'
import SiteNavi from '../SiteNavi'
import Footer from '../Footer'
import emergence from 'emergence.js'
import Helmet from 'react-helmet'

import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'
import './gatstrap.scss'
import 'blog-components/dist/index.css'

import { StaticQuery, graphql } from 'gatsby'

import { ScrollToTopOfPage } from 'blog-components'

// eslint-disable-next-line
import SiteInformation from '../../fragments/SiteInformationFragment'

if (typeof window !== 'undefined') {
  window.jQuery = window.$ = require('jquery')
  require('bootstrap')
  require('popper.js')
}

class Template extends ScrollToTopOfPage {
  componentDidMount() {
    emergence.init({ reset: false })
  }

  componentDidUpdate() {
    emergence.init({ reset: false })
  }

  render() {
    const { children } = this.props

    return (
      <StaticQuery
        query={graphql`
          query LogoImageQuery {
            site {
              ...SiteInformation
            }
            navLogo: file(relativePath: { eq: "perfectly-penned-logo.svg" }) {
              publicURL
            }
            footerLogo: file(
              relativePath: { eq: "perfectly-penned-logo-white.svg" }
            ) {
              publicURL
            }
            allPosts: allContentfulBlogPost {
              posts: edges {
                post: node {
                  categories
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <Helmet>
              <script
                type="text/javascript"
                src={
                  '//s7.addthis.com/js/300/addthis_widget.js#pubid=' +
                  data.site.siteMetadata.addThisId
                }
                async="async"
                defer="defer"
              />
              <script type="text/javascript">
                {`
                  var OneSignal = window.OneSignal || [];
                  OneSignal.push(function() {
                  OneSignal.init({
                    appId: "${data.site.siteMetadata.onesignal}",
                    autoRegister: false,
                    notifyButton: {
                      enable: true,
                    },
                  });
                  OneSignal.registerForPushNotifications(); // shows native browser prompt
                });
                  `}
              </script>
            </Helmet>
            <SiteNavi
              logo={data.navLogo.publicURL}
              posts={data.allPosts}
              site={data.site}
            />
            {children}
            <Footer logo={data.footerLogo.publicURL} />
          </div>
        )}
      />
    )
  }
}

export default Template
